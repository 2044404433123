import { Typography } from '@mui/material'
import { makeStyles } from '@/theme'

export function WithDangerNote({ text, children }) {
  const { classes, cx } = useStyles()

  if (!text) return children

  return (
    <div className={cx(classes.container)}>
      {children}
      <Typography variant="subtitle1" color="text.secondary" className={cx(classes.description)}>
        {text}
      </Typography>
    </div>
  )
}

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  description: {
    fontSize: '12px',
    paddingLeft: '12px',
  },
}))
