import { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import { Button, Tooltip } from '@mui/material'
import { makeStyles } from '@/theme'
import { FeeCategory, IAllowedFeeCategory, IFormField, IPaymentOption } from '@/models'
import { Switch } from '@/components/Switch'
import { DeleteConfirmationButton } from '@/components/DeleteConfirmationButton'
import { Select } from '@/components/Select'
import { TFormik } from '@/components/forms/Form/FormFields'

interface AllowedFeeCategoriesFormProps {
  field: IFormField
  formik: TFormik<IPaymentOption>
}

export function AllowedFeeCategoriesForm(props: AllowedFeeCategoriesFormProps) {
  const {
    field: { initialValue },
    formik,
  } = props
  const { classes, cx } = useStyles()

  const [feeCategories, setFeeCategories] = useState<IAllowedFeeCategory[]>(
    (initialValue as IAllowedFeeCategory[]) || [],
  )

  useEffect(() => {
    void formik.setFieldValue('allowedFeeCategories', feeCategories)
  }, [feeCategories])

  const allPossibleCategories = Object.values(FeeCategory)
  const presentCategories = new Set(feeCategories.map((feeCategory) => feeCategory.category))

  const hasAllCategories = () =>
    allPossibleCategories.every((category) => presentCategories.has(category)) &&
    presentCategories.size >= allPossibleCategories.length

  const selectableCategories = (selectedCategory: FeeCategory) => {
    const existentCategories = new Set(
      feeCategories
        .map((feeCategory) => feeCategory.category)
        .filter((category) => category !== selectedCategory),
    )
    return allPossibleCategories
      .filter((category) => !existentCategories.has(category))
      .map((category) => ({ label: category, value: category }))
  }

  const onAddFeeCategoryHandler = () => {
    // Find the first missing category from the FeeCategory enum, and this will be added by default in the category field
    const missingCategory = allPossibleCategories.find(
      (category) => !presentCategories.has(category),
    )

    if (missingCategory) {
      setFeeCategories([
        ...feeCategories,
        { category: missingCategory, allowFlatAmount: false, allowPercentAmount: false },
      ])
    }
  }

  const onChangeCategory = (value: FeeCategory, selectedCategory) => {
    setFeeCategories((prevFeeCategories) =>
      prevFeeCategories.map((feeCategory) =>
        feeCategory.category === selectedCategory.category
          ? {
              ...feeCategory,
              category: value,
            }
          : feeCategory,
      ),
    )
  }

  const onChangeAllowFlatAmount = ({ active, category }) => {
    setFeeCategories((prevFeeCategories) =>
      prevFeeCategories.map((feeCategory) =>
        feeCategory.category === category.category
          ? {
              ...feeCategory,
              allowFlatAmount: active,
            }
          : feeCategory,
      ),
    )
  }

  const onChangeAllowPercentAmount = ({ active, category }) => {
    setFeeCategories((prevFeeCategories) =>
      prevFeeCategories.map((feeCategory) =>
        feeCategory.category === category.category
          ? {
              ...feeCategory,
              allowPercentAmount: active,
            }
          : feeCategory,
      ),
    )
  }

  const onDeleteFeeCategory = (category) => {
    setFeeCategories((prevFeeCategories) =>
      prevFeeCategories.filter((feeCategory) => feeCategory.category !== category.category),
    )
  }

  return (
    <div className={cx(classes.container)}>
      <div className={cx(classes.headerContainer)}></div>
      <AddFeeCategoryButton
        sx={{ alignSelf: 'self-end' }}
        disabled={hasAllCategories()}
        disabledMessage={'You can only have one distinct fee category at once.'}
        onAddFeeCategory={onAddFeeCategoryHandler}
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ maxWidth: 90 }}>Category</TableCell>
              <TableCell>Flat</TableCell>
              <TableCell>Percent</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {feeCategories.map((category) => (
              <TableRow
                key={category.category}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell sx={{ maxWidth: 90 }}>
                  <Select
                    disabled={hasAllCategories()}
                    value={category.category}
                    placeholder="Fee Category"
                    onChange={(value) => onChangeCategory(value, category)}
                    options={selectableCategories(category.category)}
                  />
                </TableCell>
                <TableCell>
                  <Switch
                    onChange={(evt, active) => onChangeAllowFlatAmount({ active, category })}
                    checked={category.allowFlatAmount}
                  />
                </TableCell>
                <TableCell>
                  <Switch
                    onChange={(evt, active) => onChangeAllowPercentAmount({ active, category })}
                    checked={category.allowPercentAmount}
                  />
                </TableCell>
                <TableCell>
                  <DeleteConfirmationButton
                    onConfirmActionWithoutPromise={() => onDeleteFeeCategory(category)}
                    title="Are you sure you want to remove this fee category?"
                    text="The selected fee category will be removed. Note that might be payment configurations with this category fee applied."
                    errorMessage="Failed to update fee category"
                    successMessage="Fee category deleted"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

function AddFeeCategoryButton({ sx, disabled, disabledMessage, onAddFeeCategory }) {
  return (
    <Tooltip title={disabled ? disabledMessage : ''} followCursor>
      <div style={sx}>
        <Button
          disabled={disabled}
          onClick={onAddFeeCategory}
          variant="contained"
          size="large"
          type="button"
        >
          Add Category
        </Button>
      </div>
    </Tooltip>
  )
}

const useStyles = makeStyles()(() => ({
  container: {
    padding: '0',
    display: 'flex',
    rowGap: '16px',
    columnGap: '32px',
    flexDirection: 'column',
  },
  feeDisclaimerContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
    maxWidth: 1024,
  },
  headerContainer: { display: 'flex', justifyContent: 'space-between' },
}))
