// Token from website https://gist.githubusercontent.com/PhilipWee/3b0efbe1ec72300b61c17c2d2a0d593f/raw/da66fae89b342b2a1df6340c00b659b2a25e5e3c/debounce-hook.ts

import { useRef, useEffect } from 'react'

type TTimer = ReturnType<typeof setTimeout>

// eslint-disable-next-line
type TSomeFunction = (...args: any[]) => void
/**
 *
 * @param func The original, non debounced function (You can pass any number of args to it)
 * @param delay The delay (in ms) for the function to return
 * @returns The debounced function, which will run only if the debounced function has not been called in the last (delay) ms
 */

export function useDebounce<Func extends TSomeFunction>(func: Func, delay = 1000) {
  const timer = useRef<TTimer>()

  useEffect(() => {
    return () => {
      if (!timer.current) return
      clearTimeout(timer.current)
    }
  }, [])

  const debouncedFunction = ((...args) => {
    const newTimer = setTimeout(() => {
      func(...args)
    }, delay)
    clearTimeout(timer.current)
    timer.current = newTimer
  }) as Func

  return debouncedFunction
}
